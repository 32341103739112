const slugify = (text) =>
  text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

const isFallbackUrl = (document) => {
  if (document.docType?.name === 'Past Papers' || document.docType?.name === 'Practice Papers' || document.docType?.name === 'Textbooks' || document.docType?.name === 'Practice') {
    if (document.docExamBoard?.[0]?.name || document.docExamCourse?.[0]?.name) {
      return false;
    }
  }
  return true;
};

const getDocumentPreviewUrl = (document) => {
  if (!document?.name) {
    return undefined;
  }

  if (!document?.freeDocument || document?.hasPassword) {
    return undefined;
  }

  if (document.docType?.name === 'Past Papers' || document.docType?.name === 'Practice Papers') {
    const docType = slugify(document.docType?.name);
    const slugifiedId = slugify(document.originalId);
    if (document.docExamBoard?.[0]?.name || document.docExamCourse?.[0]?.name) {
      const parts = ['maths', 'documents', docType];
      const board = document.docExamBoard?.[0]?.name
        ? slugify(document.docExamBoard?.[0]?.name)
        : undefined;
      const course = document.docExamCourse?.[0]?.name
        ? slugify(document.docExamCourse?.[0]?.name)
        : undefined;
      const courseCode = document.docExamCourseCode?.[0]?.name
        ? slugify(document.docExamCourseCode?.[0]?.name)
        : undefined;
      const tier = document.docExamTier?.[0]?.name
        ? slugify(document.docExamTier?.[0]?.name)
        : undefined;

      if (board) parts.push(board);
      if (course) parts.push(course);
      if (courseCode) parts.push(courseCode);

      const slugifiedName = slugify(document.name);
      const moduleList = document.docExamModules?.length ? document.docExamModules : [];
      const filteredModuleList = moduleList.filter((f) => !slugifiedName.includes(slugify(f.name)));
      const modules = filteredModuleList.map((m) => slugify(m.name));

      const docNameArr = [];
      if (tier) docNameArr.push(tier);
      docNameArr.push(...modules);
      docNameArr.push(slugifiedName);
      const docName = docNameArr.join('-');
      parts.push(docName);
      return `/${parts.join('/')}`;
    } else {
      return `/maths/documents/${docType}/${slugifiedId}`;
    }
  }

  if (document.docType?.name === 'Textbooks') {
    const docType = slugify(document.docType?.name);
    const slugifiedId = slugify(document.originalId);
    if (document.docExamBoard?.[0]?.name || document.docExamCourse?.[0]?.name) {
        const parts = ['maths', 'documents', docType];
        const board = document.docExamBoard?.[0]?.name ? slugify(document.docExamBoard?.[0]?.name) : undefined;
        const course = document.docExamCourse?.[0]?.name ? slugify(document.docExamCourse?.[0]?.name) : undefined;
        const textbookType = document.docExamTextbookType?.name ? slugify(document.docExamTextbookType?.name) : undefined;
        if (board) parts.push(board);
        if (course) parts.push(course);
        if (textbookType) parts.push(textbookType);

        const slugifiedName = slugify(document.name);
        parts.push(slugifiedName);
        return `/${parts.join("/")}`;
    } else {
        return `/maths/documents/${docType}/${slugifiedId}`
    }
  }

  if (document.docType?.name === 'Practice Tests') {
    const docType = slugify(document.docType?.name);
    const slugifiedId = slugify(document.originalId);
    if (document.docExamBoard?.[0]?.name || document.docExamCourse?.[0]?.name) {
        const parts = ['maths', 'documents', docType];
        const board = document.docExamBoard?.[0]?.name ? slugify(document.docExamBoard?.[0]?.name) : undefined;
        const course = document.docExamCourse?.[0]?.name ? slugify(document.docExamCourse?.[0]?.name) : undefined;
        const practiceType = document.docExamPracticeType?.name ? slugify(document.docExamPracticeType?.name) : undefined;

        if (board) parts.push(board);
        if (course) parts.push(course);
        if (practiceType) parts.push(practiceType);

        const slugifiedName = slugify(document.name);
        parts.push(slugifiedName);
        return `/${parts.join("/")}`;
    } else {
        return `/maths/documents/${docType}/${slugifiedId}`
    }
  }

  return undefined;
};

module.exports.getDocumentPreviewUrl = getDocumentPreviewUrl;
module.exports.isFallbackUrl = isFallbackUrl;
